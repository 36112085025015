import React from "react"

function Tour() {
  return (    
    <p>
      <iframe title="DU Tour" style={{maxWidth:"500px", height:"1000px", width:"100%"}} src="https://dutchuncles.co.uk/tour.html"></iframe>            
    </p>
  )
}

export default Tour